const SUBS_COUNT = 20;
const SUBS = [...Array(SUBS_COUNT)].map((sub, index) => 'sub' + (index + 1));
const BASE_FANCY_GROUP_OPTIONS = [
  'landing', 'campaign',
  { key: 'Date', subitems: ['date', 'month'] },
  { key: 'Day parting', subitems: ['hour_of_day', 'day_of_week'] },
  {
    key: 'Connection', subitems: ['connection_type', { key: 'isp', label: 'ISP' },
       { key: 'proxy_type', label: 'Proxy type' }]
  },
  { key: 'Device', subitems: [{ key: 'device', label: 'Device type' }, 'device_brand', 'device_model'] },
  { key: 'OS', subitems: [{ key: 'os', label: 'OS type' }, { key: 'os_fullname', label: 'OS version' }] },
  {
    key: 'Browser',
    subitems: [{ key: 'browser', label: 'Browser type' }, { key: 'browser_fullname', label: 'Browser version' }]
  },
  { key: 'Geo', subitems: ['country', 'region', 'city'] }, 'language', { key: 'ua', label: 'UserAgent' },
  { key: 'Referrer', subitems: ['referrer', 'referrer_domain'] },
  { key: 'Subs', subitems: [ ...SUBS, 'deeplink' ] },
];
const BASE_GROUPING_OPTIONS = [
  'landing', 'campaign', 'date', 'month', 'hour_of_day', 'day_of_week', 'connection_type', 'isp', 'ua',
  'device', 'device_brand', 'device_model', 'os', 'os_fullname', 'browser', 'browser_fullname',
  'country', 'region', 'city', 'language', 'referrer', 'referrer_domain', 'proxy_type', ...SUBS, 'deeplink',
];

export {
  SUBS_COUNT,
  SUBS,
  BASE_FANCY_GROUP_OPTIONS,
  BASE_GROUPING_OPTIONS,
}
